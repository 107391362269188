import {ParsedUrlQuery} from 'querystring';

const auth = {
  passwordRegex:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/i,
  emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i,
  zipcodeRegex: /(^\d{5}$)|(^\d{5}-\d{4}$)/i,

  passwordError: 'Password must be 8 characters long.',

  fields: ['password', 'confirm-password'],
  loginFields: [
    {
      name: 'email',
      type: 'text',
      labelWidth: 40,
      autoComplete: 'email',
    },
    {
      name: 'password',
      type: 'password',
      autoComplete: 'current-password',
      labelWidth: 70,
    },
  ],
  passwordRules: [
    {
      rule: 'Minimum 8 characters',
      test: str => str.length >= 8,
    },
  ],
  formatRedirectUrl: (query: ParsedUrlQuery) => {
    const nextUrl = query?.next as string;
    if (nextUrl) {
      const modifiedNextUrl = `${nextUrl}${
        query.code ? `&code=${query.code}` : ''
      }`;
      return modifiedNextUrl;
    }

    const params = [];
    if (query?.code) {
      params.push(`code=${query.code}`);
    }
    if (query?.type) {
      params.push(`type=${query.type}`);
    }
    if (params.length) {
      return `/checkout?${params.join('&')}`;
    }

    return '';
  },
};

export default auth;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: any): any => ({
  main: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(/assets/images/auth/login-background.jpg)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",

    "& .backdrop": {
      zIndex: 1,
      position: "absolute",
    },
  },
  signupMain: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(/assets/images/auth/signup-background.jpeg)`,
    backgroundSize: "cover",
    backgroundPosition: "50% 80%",
    backgroundRepeat: "no-repeat",
    position: "relative",

    "& .backdrop": {
      zIndex: 1,
      position: "absolute",
    },
  },

  paper: {
    zIndex: 5,
    padding: `${theme.spacing(5)}px`,
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(8),
    height: "fit-content",
    maxWidth: 315,
    borderRadius: 16,
    width: "100%",
    textAlign: "center",
    backgroundColor: theme.palette.common.lightGray,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },

    "& > h1": {
      fontSize: "2.2rem",
      marginBottom: theme.spacing(1),

      "& ~ form": {
        marginTop: theme.spacing(3),
      },
    },

    "& > p:not(.noMargin)": {
      marginBottom: theme.spacing(3),
    },
  },

  input: {
    borderRadius: 8,
  },

  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2.5),

    "& > .control": {
      marginBottom: theme.spacing(2),
      width: "100%",
      "&:last-of-type": {
        marginBottom: theme.spacing(1.5),
      },
    },

    "& [type='submit']": {
      fontWeight: 500,
      boxShadow: "none",
      borderRadius: 8,
    },
  },

  prompt: {
    fontWeight: 400,
    fontSize: "0.875rem",
    color: theme.palette.common.black,

    "& > a, & > button, &.link": {
      textDecoration: "underline"
    },
    "&.forgotPassword": {
      textAlign: "right",
      marginBottom: theme.spacing(4),
    },
  },
  smallPrompt: {
    fontSize: "0.7rem",
    marginTop: 5,
  },

  rules: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
    padding: 0,
    paddingLeft: "1em",

    "& li": {
      flexBasis: "50%",
      fontSize: "0.75rem",
      lineHeight: "22px",
    },
  },

  checkbox: {
    margin: 0,
    marginBottom: theme.spacing(5),
    textAlign: "left",

    "& > span": {
      padding: 0,
      "&:first-of-type": {
        marginRight: theme.spacing(1),
      },
    },
  },

  modalInputWrapper: {
    boxSizing: 'border-box',
    marginTop: theme.spacing(3),
    width: "100%",
  },

  link: {
    color: theme.palette.common.black,
    fontWeight: '400',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },

  outlinedButton: {
    height: 38,
    '& img, & svg': {
      marginRight: theme.spacing(1)
    },
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";

import authStyles from "../authStyles";
import { handleError, ERRORTYPES } from "../../../util/errors";

const SEND_RESET_PASSWORD = gql`
  mutation($email: String!) {
    resetPasswordEmail(email: $email)
  }
`;

const ResetPasswordModal = props => {
  const classes: any = authStyles();
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');



  const [sendResetPasswordRequest, { loading }] = useMutation(
    SEND_RESET_PASSWORD
  );

  const { handleError: errorCallback, handleSuccess } = props;
  const handleSubmit = useCallback(() => {
    if (!value.length) {
      setError("Required");
      return;
    }

    sendResetPasswordRequest({ variables: { email: value } })
      .then(data => {
        setValue('');
        handleSuccess();
      })
      .catch(err => {
        handleError(err, (type, messages) => {
          setValue('');
          errorCallback(
            type === ERRORTYPES.GRAPHQL &&
              messages.includes("Email address doesn't exist")
              ? "A user with that email doesn't exist. Please create a new user by clicking the sign up link"
              : "Unable to reset password"
          );
        });
      });
  }, [value, sendResetPasswordRequest, errorCallback, handleSuccess]);

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby='reset-password-title'
      aria-describedby='reset-password-content'
    >
      <DialogTitle id='reset-password-title'>Reset password?</DialogTitle>
      <DialogContent>
        <DialogContentText id='reset-password-content'>
          Enter your email address and we'll send you a link to recover your
          password.
        </DialogContentText>

        <TextField
          className={classes.modalInputWrapper}
          autoFocus
          id='reset-email'
          label='Email'
          type='email'
          value={value}
          onChange={e => {
            setValue(e.target.value);
            if (e.target.value) setError('');
          }}
          error={Boolean(error)}
          autoComplete='email'
          fullWidth
          variant='outlined'
          helperText={error}
        />
      </DialogContent>

      <DialogActions className={classes.modalInputWrapper} style={{padding: '8px 24px 16px'}}>
        {!loading && (
          <Button onClick={props.handleClose} variant='outlined'>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          color='primary'
          variant='contained'
          disabled={loading}
        >
          {loading ? (
            <CircularProgress color='primary' size={22} />
          ) : (
            "Reset Password"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResetPasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default ResetPasswordModal;

import { isArray } from "lodash";

export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const ERRORTYPES = {
  GRAPHQL: "graphQL",
  NETWORK: "network",
  OTHER: "other",
};

/**
 * @callback errorCallback
 * @param {string} type
 * @param {string[]} messages
 *
 * @param {Error} error
 * @param {errorCallback} cb
 */
export function handleError(error, cb: (arg0?: any, arg1?: any) => void = () => null) {
  // graphql error
  if (
    error?.graphQLErrors &&
    isArray(error?.graphQLErrors) &&
    error?.graphQLErrors.length
  ) {
    const messages = [];
    error.graphQLErrors.forEach(err => {
      const { code } = err.extensions || {};
      console.debug(`error in ${err.path.join(", ")}: \n`, err.message);

      messages.push(err.message);
      if (code === UNAUTHENTICATED) {
        // Need to auto log out 
        console.warn('Unauthenticated', UNAUTHENTICATED)
        // throw new Error(UNAUTHENTICATED)
      };
    });

    return cb(ERRORTYPES.GRAPHQL, messages);
  }

  // network
  if (error?.networkError) {
    console.debug("network error: \n", error.networkError);
    return cb(ERRORTYPES.NETWORK, [error.networkError]);
  }

  console.debug(
    "Undetermined error: \n",
    error.name,
    "\n",
    error.message,
    "\n",
    error.stack
  );

  return cb(ERRORTYPES.OTHER, [error.message]);
}

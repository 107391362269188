import React, {useState, useMemo, useEffect, useRef} from 'react';
import {useRouter} from 'next/router';
import {
  FormControl,
  OutlinedInput,
  Button,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  Link as MuiLink,
  Typography,
  Snackbar,
  Paper,
  Backdrop,
  Box,
} from '@material-ui/core';
import {Alert as MuiAlert, AlertTitle} from '@material-ui/lab';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Visibility as ShowIcon,
  VisibilityOff as HideIcon,
} from '@material-ui/icons';
import {startCase, reduce} from 'lodash';

import authStyles from '../authStyles';
import ResetPasswordModal from '../resetPassword/ResetPasswordModal';
import util from '../../../util/auth';
import Link from 'next/link';
import {SocialAuth} from '../SocialAuth';
import authUtil from '../../../util/auth';

const LoginUI = props => {
  const {values, errors, handleChange, handleSubmit, touched, setErrors} =
    props;
  const classes: any = authStyles();
  const router = useRouter();

  const [showPassword, setShowPassword] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showExpiredMessage, setShowExpiredMessage] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetPasswordSuccess, setShowResetPasswordSuccess] =
    useState(false);
  const [showResetPasswordFail, setShowResetPasswordFail] = useState(false);
  const [resetFailMessage, setResetFailMessage] = useState('');

  const {location, hasFormErrors} = props;

  const {query} = router;
  const formattedRedirectUrl = authUtil.formatRedirectUrl(query);
  const next = formattedRedirectUrl ? `?next=${formattedRedirectUrl}` : '';

  useEffect(() => {
    // const { showAuthMessage, expired } = location?.state || {};
    const {showAuthMessage, expired} = router.query;
    if (showAuthMessage) setShowLoginError(true);
    else if (expired) setShowExpiredMessage(true);
  }, [location?.state]);

  useEffect(() => {
    if (hasFormErrors) {
      setErrors(
        reduce(
          util.loginFields,
          (acc, {name}) => {
            acc[name] = 'Invalid';
            return acc;
          },
          {},
        ),
      );
    }
  }, [hasFormErrors, setErrors]);

  const {loginFields} = util;
  const fields = useMemo(() => {
    return loginFields.map(fieldData =>
      Object.assign(
        fieldData,
        fieldData.name === 'password'
          ? {
              type: showPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <HideIcon /> : <ShowIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {},
      ),
    );
  }, [showPassword, loginFields]);

  return (
    <>
      <section className={classes.main}>
        <Backdrop className="backdrop" open transitionDuration={0} />
        <Paper className={classes.paper}>
          <Typography variant="h1">Welcome Back</Typography>
          <Typography variant="body2">
            {props.giftCardMessage ? (
              <>{props.giftCardMessage}</>
            ) : (
              <>Log in to your onWater account</>
            )}
          </Typography>

          <form onSubmit={handleSubmit} className={classes.form}>
            {fields.map(field => {
              const hasError = Boolean(
                touched[field.name] && errors[field.name],
              );

              return (
                <FormControl
                  variant="outlined"
                  key={field.name}
                  className="control">
                  <InputLabel htmlFor={field.name}>
                    {startCase(field.name)}
                  </InputLabel>
                  <OutlinedInput
                    id={field.name}
                    name={field.name}
                    type={field.type}
                    value={values[field.name]}
                    onChange={handleChange}
                    error={hasError}
                    autoComplete={field.autoComplete}
                    fullWidth
                    endAdornment={field.endAdornment}
                    labelWidth={field.labelWidth}
                    // className={classes.input}
                    inputProps={classes.input}

                  />
                  {hasError && (
                    <FormHelperText error>{errors[field.name]}</FormHelperText>
                  )}
                </FormControl>
              );
            })}

            <MuiLink
              role="button"
              className={clsx(classes.prompt, 'link', 'forgotPassword')}
              onClick={() => setShowResetPassword(true)}>
              Forgot password?
            </MuiLink>

            <Button variant="contained" color="primary" type="submit">
              Log In
            </Button>
          </form>

          <SocialAuth />

          <Typography className={clsx(classes.prompt, 'noMargin')}>
            Don't have an account?{' '}
            {props.giftCardCode ? (
              <Link href={`/gift/activate/${props.giftCardCode}`}>
                <a
                  href={`/gift/activate/${props.giftCardCode}`}
                  className={classes.link}>
                  Sign up
                </a>
              </Link>
            ) : (
              <Link href={`/subscribe${next}`}>
                <a href={`/subscribe${next}`} className={classes.link}>
                  Sign up
                </a>
              </Link>
            )}
          </Typography>
        </Paper>
      </section>

      {/* snack bars don't need to stack */}
      <Snackbar open={showLoginError} onClose={() => setShowLoginError(false)}>
        <MuiAlert severity="error">
          Your login token expired. Please log back in.
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={showExpiredMessage}
        onClose={() => setShowExpiredMessage(false)}>
        <MuiAlert severity="error">
          Your reset password link expired. Please try again.
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={Boolean(props.snackbarMessage)}
        onClose={() => props.resetSnackbarMessage(false)}>
        <MuiAlert severity="error">{props.snackbarMessage}</MuiAlert>
      </Snackbar>

      <Snackbar
        open={showResetPasswordSuccess}
        onClose={() => setShowResetPasswordSuccess(false)}>
        <MuiAlert severity="success">
          <AlertTitle>
            A reset password link has been sent to your email.
          </AlertTitle>
          If you cannot find the email in your inbox, please also check your
          spam folder.
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={showResetPasswordFail}
        onClose={() => setShowResetPasswordFail(false)}>
        <MuiAlert severity="error">{resetFailMessage}</MuiAlert>
      </Snackbar>

      {/* modal */}
      <ResetPasswordModal
        show={showResetPassword}
        handleClose={() => setShowResetPassword(false)}
        handleSuccess={() => {
          setShowResetPassword(false);
          setShowResetPasswordSuccess(true);
        }}
        handleError={err => {
          setShowResetPassword(false);
          setResetFailMessage(err);
          setShowResetPasswordFail(true);
        }}
      />
    </>
  );
};

LoginUI.propTypes = {
  loading: PropTypes.bool,
  snackbarMessage: PropTypes.string,
  resetSnackbarMessage: PropTypes.func.isRequired,
  hasFormErrors: PropTypes.bool.isRequired,
};

LoginUI.defaultProps = {
  loading: false,
  snackbarMessage: '',
};

export default LoginUI;
